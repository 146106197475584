.profile-container {
  max-width: 500px;
}
.quick-driver-container {
  max-width: 600px;
}
.rounded {
  border-radius: $btn-border-radius !important;
}
.user-notifiers {
  padding: 3px;
  .nav-item {
    .nav-link {
      border-radius: 9px;
      padding: 11px;
      line-height: 20px;
    }
  }
}

.user-image {
  .btn {
    height: 36px;
    width: 36px;
    vertical-align: bottom;
    margin-left: -24px;
    border: 2px solid $white;
    padding: 4px;
  }
}
