.search-decisions-filter {
  position: relative;

  .filter-menu {
    position: absolute;
    z-index: 1;
    width: 312px;
    right: 0;
    height: auto;

    .accordion {
      max-height: 65vh;
      overflow: auto;
      background-color: $white;
    }

    &::before {
      height: 12px;
      display: block;
      background: $white;
      border: $card-border-width solid $card-border-color;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      border-bottom: unset;
      content: '';
    }

    &::after {
      height: 12px;
      display: block;
      background: $white;
      border: $card-border-width solid $card-border-color;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      content: '';
    }
  }
}

.selected-actions-text {
  text-overflow: ellipsis;
  overflow: hidden;
}

.filter-text {
  white-space: nowrap;
}

.search-actions.filter-text {
  max-width: 140px;
}

.card {
  overflow: visible !important;
  border-radius: 0;

  &:first-child {
    border-top: unset;

    .btn.likes-link {
      padding-top: 1px !important;
      height: 35px;
    }

    .btn:not(.likes-link) {
      padding-top: 12px !important;
    }

    &.shadow {
      box-shadow: unset !important;
    }
  }

  &:last-child {
     &.shadow {
       box-shadow: unset !important;
     }
   }

  .card-body {
    position: relative;
  }

  .rbt-input-multi {
    padding-right: 34px
  }

  &:last-of-type {
    .card-header {
      border-radius: inherit !important;
    }
  }
}
.orange-bubble {
  position: relative;
  @include orange-bubble(initial, initial);
}

.filter-pills {
  button {
    line-height: 1rem;
  }
}

@media only screen and (max-width: 767px) {
  .search-decisions-filter {
    .filter-menu.more-filter {
      right: -60px;
    }
  }
}
@media only screen and (max-width: 1308px) {
  .search-decisions-filter {
    .filter-menu {
      right: unset;
    }
  }
}
