.btn {
  border-radius: $btn-border-radius;
  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:not(.btn-group, .dropdown-toggle):first-child:active,
  &.active,
  :not(.btn-group) + &.show {
    color: $gray;
    background-color: $light;
  }
}

//custom class created for circle buttons
.btn-lg-round {
  border-radius: 50%;
  font-size:1.25rem;
  height: 3rem;
  width: 3rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  &.header-toggle-light {
    font-size:0.875rem !important;
  }
}
.btn-sm-round {
  border-radius: 50%;
  height: 2.25rem;
  min-width: 2.25rem;
  width: 2.25rem;
  font-size: 0.875rem;
  padding: 0.5rem 0;
}
.icon {
  .btn-sm-round {
    padding: 0.625rem 0;
  }
  &.btn-lg-round {
    padding-left: 0.7rem;
    padding-right: 0.7rem;
  }
  &:not(.no-pointer):hover {
    cursor: pointer;
  }
  &.likes-link {
    margin-top: -16px;
    border-radius: 20px;
    line-height: 32px;
    .rounded-circle {
      font-size: 18px;
      line-height: 32px;
    }
  }
  &.as-public-visitor {
    &:hover {
      cursor: default;
    }
  }
  &.close-icon:hover {
    filter: invert(0.4)
  }
  .rounded-circle {
    width: 48px;
    height: 48px;
  }
}

.btn-primary {
  @include button-variant($primary, $primary, $white, shift-color($primary, 10%), shift-color($primary,10%), $white, shade-color($primary, 20%), shade-color($primary,20%), $white);
}

.btn-outline-primary {
  @include button-outline-variant($primary, #222222, tint-color($primary,5%), $primary);
}

.btn-secondary {
  @include button-variant($secondary, $secondary, $primary, $darken-btn-secondary-background, $darken-btn-secondary-background, $darken-btn-secondary-text, $darken-btn-secondary-background, $darken-btn-secondary-background, $darken-btn-secondary-text);
}

.btn {
  &.disabled, &:disabled {
    color: $gray;
    background-color: $light;
    border-color: $light;
    opacity: 1;
    cursor: not-allowed;
  }
  height: 3rem;
  &.btn-sm {
    height: 2.25rem;
  }
}

.btn-outline-secondary {
  @include button-outline-variant($secondary, #222222, tint-color($secondary,5%), $secondary);
}

.btn-light {
  @include button-variant($light, $light, $darken-btn-secondary-text, $darken-btn-light-background, $darken-btn-light-background, $darken-btn-secondary-text, $darken-btn-light-background, $darken-btn-light-background, $darken-btn-secondary-text);
}

.btn-link {
  padding: .375rem .75rem;
}

.dropdown-toggle{
  &::after {
    display: inline-block;
    width: .535rem;
    height: .535rem;
    margin: 0 0 0 8px;
    content: "";
    border: solid;
    border-width: 0 .2em .2em 0;
    transform: rotateZ(45deg);
    transition-duration: 0.5s;
    color: $primary;
  }
  &.open {
    &::after {
      transform: rotate(225deg);
      transition-duration: 0.5s;
    }
  }
  &.btn-link {
    width: inherit;
  }
}

.sharing-button {
  .dropdown-menu {
    width:100%;
  }
}

.discussion-btn {
  min-width: 48px;
}

.settings-dropdown {
  .dropdown-menu {
    margin-top: -30px;
  }
}

.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  height: 21px;
  width: 21px;
  &:checked:disabled+label:before{
    background-color: $primary !important;
  }
  &:disabled+label:before {
    background-color: $white !important;
  }
}
.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid $primary;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.notification-checkbox+label::before {
  border: 1px solid $primary;
}
.custom-checkbox:checked+label::before {
  border-color: $primary;
  background-color: $primary;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.notification-checkbox:checked+label::before {
  border-color: $primary;
  background-color: $primary;
}
/* styles for mouse on checkbox */
.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: $primary;
}
/* styles for mouse on checkbox */
.notification-checkbox:not(:disabled):not(:checked)+label:hover::before {
  border-color: $primary;
}
/* styles for active checkbox state */
.custom-checkbox:not(:disabled):active+label::before {
  background-color: $primary;
  border-color: $primary;
}
/* styles for active checkbox state */
.notification-checkbox:not(:disabled):active+label::before {
  background-color: $primary;
  border-color: $primary;
}
/* styles for unchecked checkbox in focus */
.custom-checkbox:focus:not(:checked)+label::before {
  border-color: $primary;
}
/* styles for unchecked checkbox in focus */
.notification-checkbox:focus:not(:checked)+label::before {
  border-color: $primary;
}
/* styles for disabled checkbox */
.custom-checkbox:disabled+label::before {
  background-color: $gray;
}
/* styles for disabled checkbox */
.notification-checkbox:disabled+label::before {
  background-color: $primary;
}

.no-before:before {
  display: none !important;
}

.no-after {
  &:after {
    display: none;
  }
}

.btn-dsight {
  display: flex;
  justify-content: space-around;
  align-items: center;
  line-height: 1rem;
  height: 35px;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-tree-navigation {
  padding: 10px 11px;
}

.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: auto;
}

.form-check-input {
  height: 20px;
  width: 20px;
  margin: 0 12px 0 0;
  border-color: $primary;
  box-shadow: none !important;

  &:active {
    filter: none !important;
  }
  &:focus {
    border-color: $primary !important;
  }

  &:checked {
    border-width: 4px;
  }
}

