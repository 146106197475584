.tab-content {
  padding: 24px;
  height: calc(100vh - 72px);
}

.ag-theme-quartz {
  --ag-header-background-color: #F2F6FA !important;
  --ag-icon-font-color-tree-open: #2487BF !important;
  --ag-icon-font-color-tree-closed: #2487BF !important;
}

.ag-quartz-styles {
  height: calc(100vh - 192px);
}

.forecast-simulator-header {
  background: $body-background;

  .nav-tabs {
    margin-bottom: -11px;
    align-self: self-end;
    border-bottom: $white;
    border-color: $white;

    .nav-item {
      text-align: center;
      border-bottom: $white;

      .nav-link {
        background: $light-gray;
        height: 48px;


        &.active {
          background: $white;
          border-bottom: $white;
        }

        &:hover {
          border-bottom: none;
          border-color: $body-background;
        }
      }
    }
  }
}

.decision-tree-header {
  &.actions-row {
    position: inherit;
  }
}

@media only screen and (min-width: 1024px) {
  .forecast-simulator-header {
    .nav-tabs > .nav-item {
      width: 158px;
    }
  }
}