.groups-container {
  //max-width: 936px;
}
.groups-tile {
  border-radius: $btn-border-radius;
}
.groups-menu {
  max-width: 296px;
  height: fit-content;
  .list-group {
    border-radius: unset;
    .list-group-item {
      color: $muted;
      &.active {
        background-color: white;
        color: $dark;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .groups-content {
    //max-width: 616px;
  }
}