@mixin orange-bubble($left: 40px, $bottom: 18px, $bottom_mobile: 10px) {
  &:after{
    position: absolute;
    content: '';
    background-color: $orange;
    width: 8px;
    height: 8px;
    bottom: $bottom;
    left: $left;
    border: 1px $white solid;
    border-radius: 12px;
    @media only screen and (max-width:991px) {
      bottom: $bottom_mobile;
    }
  }
}

@mixin pagination() {
  a:hover {
    text-decoration: none;
  }

  .page-item {
    .page-link {
      background-color: unset;
      font-weight: 500;

      &.rounded {
        border-radius: 10px !important;
      }
    }
  }

  .page-item.active {
    .page-link {
      background-color: $primary;
    }
  }
}

@mixin hidden-scrollbar() {
  background-color: transparent;
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent !important;
  }

  &::-webkit-scrollbar-track {
    background: unset !important;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: transparent !important;
    scrollbar-track-color: transparent !important;
  }
}